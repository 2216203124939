import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Page from '../../components/page';
import ConquestTeams from '../../components/conquest';
import ConquestMenu from './menu';
import Countdown from 'react-countdown';
import { daily, planProgress } from './data/daily';
import { gold, goldProgress } from './gold/daily';


const dayRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return <div>{days}d {hours}h {minutes}m {seconds}s</div>;
  }
};

const FeatBadge = (props) => <span className='badge rounded-pill badge-info mx-1'>{props.text}</span>

const Intro = (props) => <div>A guide with suggested teams to help you achive max rewards with minimum refreshes. Key units for feats are marked in <span className='badge rounded-pill badge-primary mx-1'>Yellow</span>.
  <div className="d-flex align-items-center mt-3">
    <div className="form-group-label mb-0 ml-0 mr-3">Crate Selection</div>
    <div className='btn-group btn-group-sm'>
      <button className={classNames('btn btn-outline-danger px-3', props.value === 'all' && 'active')} onClick={() => props.change('all')}>
        Red Crate
      </button>
      <button className={classNames('btn btn-outline-warning px-3', props.value === 'gold' && 'active')} onClick={() => props.change('gold')}>
        Gold Crate
      </button>
    </div>
  </div>
  <hr className='bg-light' />
  <div className="mt-3">
    {props.value === 'all' && <>
      <div className='h3'>Red Crate</div>
      The daily plan for Red Crate is skipping the below feats (30 keycards)<br /> <FeatBadge text='Morgan' /> <FeatBadge text='Alert' /> <FeatBadge text='Jar Jar' /> <FeatBadge text='Amidala' /> <FeatBadge text='Gungans' /> <FeatBadge text='Luthen' /><br /><br />
      <strong>Total of up to 34 keycards can be skipped to unlock Red Crate.</strong>
    </>}
    {props.value === 'gold' && <>
      <div className='h3'>Gold Crate</div>
      The daily plan for Gold Crate is skipping the below feats (128 keycards)<br /> <FeatBadge text='Imperial Remnants' /> <FeatBadge text='Gungans' /> <FeatBadge text='Peridea' /> <FeatBadge text='Padawan Obi-Wan' /> <FeatBadge text='DT Gideon' /> <FeatBadge text='Blight' /> <FeatBadge text='Retaliate' /> <FeatBadge text='Geos' /> <FeatBadge text='Tarpals' /> <FeatBadge text='Breach' /> <FeatBadge text='Decay' /> <br /><br />
      <strong>Total of up to 134 keycards can be skipped to unlock Gold Crate.</strong>
    </>}
  </div>
</div>;

const ConquestPlan = () => {
  const [selectedDay, setDay] = useState(0);
  const [trackProgress, setProgress] = useState([]);
  const [planData, setPlanData] = useState(daily);
  const [crate, setCrate] = useState('red');
  const [planValue, setPlanValue] = useState('all');
  const [conquestLive] = useState(true);

  useEffect(() => {
    document.title = 'Conquest Daily Plan - SWGOH-4 Life';
    const dayPlan = JSON.parse(localStorage.getItem("dayPlan"));
    dayPlan === null ? setDay(0) : setDay(dayPlan);
    const getTrack = JSON.parse(localStorage.getItem("trackConquest45"));
    const getUnit = localStorage.getItem("unitConquest45")
    getTrack === null ? setProgress(getUnit === 'gold' ? goldProgress : planProgress) : setProgress(getTrack);
    setCrate(getUnit === 'gold' ? 'gold' : 'red')
    getUnit && loadPlan(getUnit, true);
  }, []);

  const onChangeDay = (d, top) => {
    setDay(d);
    localStorage.setItem("dayPlan", JSON.stringify(d));
    top && window.scroll({
      top: 100,
      behavior: "smooth"
    });
  }

  const onCompleteBattle = (id) => {
    let getBattle = trackProgress.find(b => b.id === id);
    let updateBattle = {
      ...getBattle, completed: getBattle.completed + 1
    };
    let updateTracker = trackProgress.map(b => b.id === id ? updateBattle : b);
    setProgress(updateTracker);
    localStorage.setItem("trackConquest45", JSON.stringify(updateTracker));
  }

  const onResetBattle = (id) => {
    let getBattle = trackProgress.find(b => b.id === id);
    let updateBattle = {
      ...getBattle, completed: 0
    };
    let updateTracker = trackProgress.map(b => b.id === id ? updateBattle : b);
    setProgress(updateTracker);
    localStorage.setItem("trackConquest45", JSON.stringify(updateTracker));
  }

  const loadPlan = (v, onLoad) => {
    if (v === 'all') {
      setPlanData(daily);
      !onLoad && setProgress(planProgress);
    } else if (v === 'gold') {
      setPlanData(gold);
      !onLoad && setProgress(goldProgress);
    }
    setCrate(v)
    setPlanValue(v);
    localStorage.setItem("unitConquest45", v);
  }

  const onPlanChange = (s) => {
    let v = s;
    loadPlan(v);
    onChangeDay(0);
    localStorage.removeItem("trackConquest45");
  }

  return (
    <Page title="Conquest Daily Plan" intro={<Intro change={onPlanChange} value={planValue} />} menu={<ConquestMenu />}>
      {conquestLive ?
        <>
          <div className="mb-5 mb-md-6 row justify-content-center align-items-center">
            <div className='col-12 col-sm pb-3 pb-sm-0 order-sm-1'>
              <div className='row'>
                <div className='col-6 col-sm-12 col-md text-right'>
                  <strong>Event ends in</strong>
                </div>
                <div className='col-6 col-sm-12 col-md-auto pl-0 text-sm-right text-monospace'>
                  <Countdown date={new Date('2024-09-16T18:00:00Z')} renderer={dayRenderer} />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <button
                disabled={selectedDay === 0}
                className={classNames('d-print-none btn py-2 px-4', selectedDay === 0 ? 'btn-outline-info' : 'btn-outline-light')}
                onClick={() => onChangeDay(selectedDay - 1)}>Prev</button>
            </div>
            <div className="col-auto">
              <h2 className="h2 mb-0">Day {selectedDay + 1}</h2>
            </div>
            <div className="col-auto">
              <button
                disabled={selectedDay === 13}
                className={classNames('d-print-none btn py-2 px-4', selectedDay === 13 ? 'btn-outline-info' : 'btn-outline-light')}
                onClick={() => onChangeDay(selectedDay + 1)}>Next</button>
            </div>
          </div>
          {planData && planData[selectedDay].length > 0 ? planData[selectedDay].map((feat, i) =>
            <ConquestTeams
              squads={feat.teams} key={i} title={feat.title} boss={feat.boss}
              progress={feat.progress} planProgress={trackProgress.length > 0 && trackProgress}
              onComplete={onCompleteBattle} onReset={onResetBattle} dataDisk={feat.dataDisk}
            />
          ) :
            <div className='text-center mb-5'>
              <div className="alert alert-dark mt-3 d-inline-flex">Daily plan for Day {selectedDay + 1} coming soon!</div>
            </div>
          }

          {selectedDay === 13 && crate !== 'gold' &&
            <div className="mb-5 mb-md-6 row justify-content-center align-items-center">
              <div className="col-12 col-md-10 col-lg-8">
                <img alt="" className="img-fluid" src={`/images/conquest/congrats.png`} />
              </div>
            </div>
          }

          <div className="mb-5 mb-md-6 row justify-content-center align-items-center">
            <div className='col-12 col-sm pb-3 pb-sm-0 order-sm-1'>
              <div className='row'>
                <div className='col-6 col-sm-12 col-md text-right'>
                  <strong>Event ends in</strong>
                </div>
                <div className='col-6 col-sm-12 col-md-auto pl-0 text-sm-right text-monospace'>
                  <Countdown date={new Date('2024-09-16T18:00:00Z')} renderer={dayRenderer} />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <button
                disabled={selectedDay === 0}
                className={classNames('d-print-none btn py-2 px-4', selectedDay === 0 ? 'btn-outline-info' : 'btn-outline-light')}
                onClick={() => onChangeDay(selectedDay - 1, true)}>Prev</button>
            </div>
            <div className="col-auto">
              <h2 className="h2 mb-0">Day {selectedDay + 1}</h2>
            </div>
            <div className="col-auto">
              <button
                disabled={selectedDay === 13}
                className={classNames('d-print-none btn py-2 px-4', selectedDay === 13 ? 'btn-outline-info' : 'btn-outline-light')}
                onClick={() => onChangeDay(selectedDay + 1, true)}>Next</button>
            </div>
          </div>
        </>
        :
        <div>

          <ConquestTeams
            title="Daily Plan Under Construction" boss="construction"
          />
        </div>
      }
    </Page>
  );
}

export default ConquestPlan;
