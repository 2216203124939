import React, { useState } from 'react'
import AddDatacronSelect from './select'
import UnitSearch from '../../5v5/search'
import AddDatacronStats from './stats'
import { toons } from '../../gac/data/planner/characters'
import { set17Bonus } from '../data/set17-bonuses'
import { set16Bonus } from '../data/set16-bonuses'
import { formatList, getSelectList } from './utils'

const set17Stats = ['Critical Avoidance', 'Defense', 'Critical Damage', 'Tenacity', 'Potency', 'Armor Penetration', 'Resistance Penetration']
const set16Stats = ['Critical Chance', 'Critical Avoidance', 'Accuracy', 'Critical Damage', 'Armor Penetration', 'Offense']

const AddDatacronModal = (props) => {
  const { toggle, activeTab, onAdd } = props
  const [leader, setLeader] = useState([])
  const [l3Value, setL3Value] = useState([])
  const [l6Value, setL6Value] = useState([])
  const [l9Value, setL9Value] = useState([])
  const [statOne, setStatOne] = useState('')
  const [statTwo, setStatTwo] = useState('')

  const getDatacrons = activeTab === 0 ? set17Bonus : set16Bonus
  const getStats = activeTab === 0 ? set17Stats : set16Stats
  const l3List = formatList(getSelectList(getDatacrons).level3)
  const l6List = formatList(getSelectList(getDatacrons).level6)
  const l9List = formatList(getSelectList(getDatacrons).level9)

  const onAddClick = () => {
    onAdd({ level3: l3Value, level6: l6Value, level9: l9Value, stats: [statOne, statTwo], unit: leader })
  }

  return (
    <div className="sw-zone__backdrop text-left" onClick={() => toggle(false)}>
      <div className="sw-zone__search sw-zone__search--datacrons" onClick={(e) => e.stopPropagation()}>
        <div className="sw-zone__add-header">Add New Datacron</div>
        <div className="sw-dc-modal">
          <div className='mb-3'>
            <UnitSearch
              list={toons}
              onChange={setLeader}
              placeholder='Squad Leader'
              selected={leader}
              isSelect
              clearButton
            />
          </div>
          {l3List.length > 0 &&
            <AddDatacronSelect
              label='Level 3'
              options={l3List}
              id='addDatacronL3'
              selected={l3Value}
              onChange={setL3Value}
            />
          }
          {l6List.length > 0 &&
            <AddDatacronSelect
              label='Level 6'
              options={l6List}
              id='addDatacronL6'
              selected={l6Value}
              onChange={setL6Value}
            />
          }
          {l9List.length > 0 &&
            <AddDatacronSelect
              label='Level 9'
              options={l9List}
              id='addDatacronL9'
              selected={l9Value}
              onChange={setL9Value}
            />
          }
          <AddDatacronStats
            onChange={setStatOne}
            selected={statOne}
            list={getStats}
            label='Primary Stat'
          />
          <AddDatacronStats
            onChange={setStatTwo}
            selected={statTwo}
            list={getStats}
            label='Secondary Stat'
          />
        </div>
        <div className="sw-dc-modal__footer">
          <button className='btn btn-outline-dark' onClick={() => toggle(false)}>Cancel</button>
          <button
            className='btn btn-primary'
            disabled={!(leader.length > 0 && (l3Value.length > 0 || l6Value.length > 0 || l9Value.length > 0))}
            onClick={onAddClick}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddDatacronModal;