import React, { useState } from 'react'
import AddDatacronModal from './add-modal'

const AddDatacron = (props) => {
  const { activeTab, accountString, list, setList } = props
  const [showModal, toggleShowModal] = useState(false)

  const onAdd = (selected) => {
    const { level3, level6, level9, stats, unit } = selected
    const getUnit = unit.length > 0 ? unit[0] : ''
    const getL3 = level3.length > 0 ? level3[0] : ''
    const getL6 = level6.length > 0 ? level6[0] : ''
    const getL9 = level9.length > 0 ? level9[0] : ''

    let addDatacron = {
      id: `${getUnit.base_id}-${getL3.id}-${getL6.id}-${getL9.id}-${list.length + 1}`,
      unit: getUnit.ggImage,
      ggImage: true,
      order: list.length + 1,
      complete: false,
      hide: false,
      stats: []
    }

    if (getL3) {
      addDatacron = { ...addDatacron, l3: { id: getL3.id, important: true } }
    }
    if (getL6) {
      addDatacron = { ...addDatacron, l6: { id: getL6.id, important: true } }
    }
    if (getL9) {
      addDatacron = { ...addDatacron, l9: { id: getL9.id, important: true } }
    }
    if (stats.length > 0) {
      addDatacron = { ...addDatacron, stats: stats }
    }

    console.log({ addDatacron })

    const updateList = [...list, addDatacron]

    if (activeTab === 0) {
      localStorage.setItem(`dcFarmingList17${accountString}`, JSON.stringify(updateList))
    } else {
      localStorage.setItem(`dcFarmingList16${accountString}`, JSON.stringify(updateList))
    }
    setList(updateList)
    toggleShowModal(false)
  }

  return (
    <div className='sw-dc-add text-center mt-5'>
      <button className='btn btn-outline-primary' onClick={() => toggleShowModal(true)}>Add Datacron</button>
      <hr className='mt-5' />
      {showModal && <AddDatacronModal activeTab={activeTab} onAdd={onAdd} show={showModal} toggle={toggleShowModal} />}
    </div>
  );
}

export default AddDatacron;